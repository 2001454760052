import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ConfigureIcon, DelIcon, ImportIcon, CopyIcon, BackArrow } from '../../assets/images'

import { getWFMasterList, loadWorkflows, workFlowOperations } from '../../services/workflowService'
import { GetEventTemplateList } from '../../services/configurationServices'
import DefaultNode from './DecisisonNode'

import ReactFlow, {
    ReactFlowProvider,
    removeElements,
    updateEdge,
    addEdge,
    Controls,
    getOutgoers,
} from 'react-flow-renderer';
import { Form, OverlayTrigger, Row } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { toast, ToastContainer } from 'react-toastify';
import { toPng } from 'html-to-image'
import jsPDF from 'jspdf'

import { initialElements } from './InitialElements'
import { ConvertDate, ConvertDateToUTC } from '../../utils/utils'
import { mixpanel } from '../../utils/MixPanelUtil'
import ConfigureAction from './ConfigureAction'
import ActionsList from './ActionsList'

const nodeTypes = {
    default: DefaultNode
};
let userid = localStorage.getItem('UserID')

let defaultWf = {
    eventTemplateID: 0,
    metadata: {
        elements: [...initialElements("Mod", "Moderate")]
    },
    workflowID: 0,
    workflowMetadataID: 0,
    workflowName: ""
}
const downloadImage = (dataUrl, file_name) => {
    /* ------------------------- JPG/PNG Download Block ------------------------- */
    // let file_n = file_name + '.png'
    // const a = document.createElement('a');
    // a.setAttribute('download', file_n);
    // a.setAttribute('href', dataUrl);
    // a.click();

    /* --------------------------- PDF Download Block --------------------------- */
    const doc = new jsPDF('p', 'pt', 'a4');
    let width = doc.internal.pageSize.getWidth()
    let height = doc.internal.pageSize.getHeight()
    doc.addImage(dataUrl, null, 0, 0, width, height, null, 'FAST');
    doc.save(`${file_name}.pdf`);
}
export default function WorkflowContainer() {
    let history = useHistory()
    let { id } = useParams()
    const reactFlowWrapper = useRef(null);

    //#region useState start
    const [mastersList, setMastersList] = useState(null)
    const [actions, setActions] = useState(null)
    const [elements, setElements] = useState();
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null)
    const [selectedWf, setSelectedWf] = useState(null)
    const [templatesUI, setTemplatesUI] = useState(null)
    const [selectedTemplateUI, setSelectedTemplateUI] = useState(null)
    const [workflowName, setWorkflowName] = useState('')
    const [isClicked2, setIsClicked2] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null)
    const [userActions, setUserActions] = useState([])
    //#endregion useState end

    //#region useEffect start
    useEffect(() => {
        (async () => {
            userid = localStorage.getItem('UserID')
            getMasterList()
             await getWorkflows(id)
             await getEventTemplatesList()
        })();
    }, [])

    useEffect(() => {
        if (templatesUI != null && selectedWf) {
            let temp = templatesUI.find(x => x.value == selectedWf.AlertTemplateId)
            setSelectedTemplateUI(temp)
        }
    }, [templatesUI])

    useEffect(() => {
        if (selectedWf != null) {
            console.log("GetData", selectedWf.metadata.elements)
            let tempData = selectedWf.metadata.elements.map((item) => {
                if(item.type == "default" && item.data.label.includes("ERT")){
                    console.log("GetData", item.data.label)
                    item.data.label = item.data.label.replaceAll("ERT", "FR");
                    console.log("GetData", item.data.label)
                    return item;
                }else{
                    return item;
                }
            });
            setElements(tempData);
            // setElements(selectedWf.metadata.elements)
            setWorkflowName(selectedWf.workflowName)
        }
    }, [selectedWf])

    useEffect(() => {
        if (actions && selectedUser != null) {
            let data = actions.filter(x => x.nodeUserID == selectedUser)
            setUserActions(data)
        }
    }, [selectedUser])
    //#endregion useEffect end

    //#region functions start
    const getMasterList = async () => {
        await getWFMasterList().then((msList) => {
            if (msList.status == "Ok") {
                setMastersList(msList.message)
                setActions(msList.message.nodeMasterList)
            }
        })
    }

    const getWorkflows = async (wfId) => {
         await loadWorkflows(userid).then((resp) => {
            if (resp) {
                if (resp.message != null) {
                    if (wfId != 0) {
                        let wf = resp.message.find(x => x.workflowID == wfId)
                        if (wf != undefined) {
                            setSelectedWf(wf)
                        }
                    } else {
                        setSelectedWf(defaultWf)
                        console.log("GetData", defaultWf.metadata.elements)
                        let tempData = defaultWf.metadata.elements.map((item) => {
                            if(item.type == "default" && item.data.label.includes("ERT")){
                                console.log("GetData", item.data.label)
                                item.data.label = item.data.label.replaceAll("ERT", "FR");
                                console.log("GetData", item.data.label)
                                return item;
                            }else{
                                return item;
                            }
                        });
                        console.log("GetData", tempData);
                        // setElements(tempData);
                        setElements(defaultWf.metadata.elements)
                    }
                }
            }
        })
    }

    const getEventTemplatesList = async () => {
        await GetEventTemplateList().then((resp) => {
            if (resp && resp.length > 0) {
                let data = []
                resp.map(item => data.push({ value: item.AlertTemplateId, label: `${item.TemplateName}-${item.SeverityTitle}`, severityID: item.SeverityID, severityTitle: item.SeverityTitle, templateName: item.TemplateName }))
                setTemplatesUI(data)
            }
        })
    }
    const onActionDrag = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('stepdata', event.target.innerText);
        event.dataTransfer.setData('stepId', event.target.id);
        event.dataTransfer.effectAllowed = 'move';
    };

    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        _reactFlowInstance.fitView()
    };

    const getId = () => {
        if (elements != null) {
            let maxValueOfY = elements.filter(o => {
                if (o.type != "smoothstep") {
                    return parseInt(o.id)
                }
            }).map(els => parseInt(els.id))

            let matrix = Math.max(...maxValueOfY)
            return `${++matrix}`
        }
    };

    const onDrop = (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const stepdata = event.dataTransfer.getData('stepdata');
        const nodeId = event.dataTransfer.getData('stepId');
        try {
            if (stepdata == "Autoclose alert") {
                var closeobj = elements.find(x => x.data.label == "Operator closes the alert")
                if (closeobj) { return }
            } else if (stepdata == "Operator closes the alert") {
                var closeobj = elements.find(x => x.data.label == "Autoclose alert")
                if (closeobj) { return }
            }
        } catch (error) {
            console.error(error)
        }

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        const newNode = {
            id: getId(),
            type,
            position,
            data: {
                content: <>{type}</>,
                label: stepdata,
                nodeId: nodeId,
                otherProps: {
                    action: stepdata,
                    actionPerformedBy: 4
                }
            }
        };
        console.warn({ newNode })
        setSelectedNode(newNode)
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onConnect = (params) => {
        let isAlreadyPresent = elements.filter(item => item.type == "smoothstep").find(element => {
            return element.source == params.source
        })
        if (isAlreadyPresent == undefined) {
            setElements((els) => addEdge({
                ...params,
                type: 'smoothstep',
                arrowHeadType: 'arrowclosed'
            }, els));
        }
    }

    const onEdgeUpdate = (oldEdge, newConnection) =>
        setElements((els) => updateEdge(oldEdge, newConnection, els));

    const onElementsRemove = (elementsToRemove) => {
        try {
            let data = elementsToRemove.find(item =>
                item.type == "default" ||
                item.type == "input" ||
                item.type == "smoothstep")

            if (data.id == 1 || data.id == 2 || data.id == "e1-2") return

            setElements((els) => removeElements(elementsToRemove, els));
        } catch (error) {
            console.error({ error })
        }
    }

    const onSelectElement = (event, element) => {
        if (!(element.data.label === "Alert Generated" || element.data.label === "Alert is Displayed to an Operator")) {
            setSelectedNode(element)
        }
    }

    const onSaveFormData = (nodeToAdd) => {
        const updatedItems =
            elements.map(el => el)

        var foundIndex = updatedItems.findIndex(x => x.id == nodeToAdd.id);

        if (foundIndex != -1) {
            updatedItems[foundIndex] = nodeToAdd;
            setElements(updatedItems);
        } else {
            setElements((es) => es.concat(nodeToAdd));
        }
        setSelectedNode(null)
    }

    const onArrange = (elements) => {
        try {
            let arrangedData = []
            let data = elements.filter(element => element.type == "default" || element.type == "input")
            if (data.length == 0) return;

            let element = data[0];
            for (let i = 0; i < data.length; i++) {
                let outnode = getOutgoers(element, elements)
                outnode.map((value, index, array) => {
                    let found = arrangedData.find(item => item.id == value.id)
                    // console.log({ element }, { found }, { value }) // For reference
                    if (found == undefined) {
                        element = value
                        arrangedData.push(value)
                    }
                })
            }

            let edges = elements.filter(element => element.type == "smoothstep")
            let arrangedElements = [{
                id: '1',
                type: 'input',
                data: {
                    content: <>{'input'}</>,
                    label: 'Alert Generated'
                },
                position: { x: 250, y: 5 }
            },
            ...arrangedData,
            ...edges]
            return arrangedElements
        }
        catch (e) {
            console.error({ e })
        }
    }

    const fetchPayload = () => {
        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            let flowData = onArrange(flow.elements)

            //set initial workflow's elements intent
            let templateName = "Alert"
            let templateSeverity = "Severity"

            let data = {}
            if (flowData) {
                let finalFlowData = flowData.map((value, index, array) => {
                    if (value.id == 2 && value.type == "default") {
                        value.data.otherProps.intentName = `${templateName.toLowerCase().replaceAll(' ', '_')}_${templateSeverity.toLowerCase()}_alertgenerated`
                        value.data.otherProps.intentText = `${templateName} ${templateSeverity}`
                        return value
                    } else {
                        return value
                    }
                })
                
                let tempFinalFlowData = finalFlowData.map((item) => {
                    console.log("finalFlowData", item)
                    if(item.type == "default" && item.data.label.includes("FR")){
                        item.data.label = item.data.label.replaceAll("FR", "ERT")
                        return item;
                    }else{
                        return item;
                    }
                })
                flow.elements = tempFinalFlowData

                if (selectedWf != null) {
                    if (selectedWf.workflowID != 0) {
                        data = {
                            action: "updateWF",
                            workflowID: selectedWf.workflowID,
                            workflowName: workflowName,
                            alertTemplateName: "Alert",
                            alertTemplateID: "0",
                            modifiedOn: ConvertDate(new Date()),
                            modifiedBy: userid,
                            metadata: {
                                ...flow,
                                severity: "Severity"
                            }
                        }
                    } else {
                        data = {
                            action: "create",
                            workflowName: workflowName,
                            alertTemplateName: "Alert",
                            alertTemplateID: "0",
                            createdOn: ConvertDate(new Date()),
                            createdBy: userid,
                            metadata: {
                                ...flow,
                                severity: "Severity"
                            }
                        }
                    }
                } else {
                    data = {
                        action: "create",
                        workflowName: workflowName,
                        alertTemplateName: "Alert",
                        alertTemplateID: "0",
                        createdOn: ConvertDate(new Date()),
                        createdBy: userid,
                        metadata: {
                            ...flow,
                            severity: "Severity"
                        }
                    }
                }
            }
            return data;
        }
    }

    const showErrorToast = (msg) => {
        toast.error(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showSuccessToast = (msg) => {
        toast.success(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

//The logic for Saving is change alert template is no loger set in workflow table 24-11-2023
    const onSaveWorkflow = async () => {
        if (window.confirm("Do you want to save the workflow?")) {
            let obj = fetchPayload()
            if (obj.metadata.elements == undefined) { showErrorToast('Workflow cannot be empty!'); return }
            if (obj.workflowName.trim() == "") { showErrorToast('Please enter workflow name!'); return }
            if (obj.action == "create") {
                try {
                    var createdtime = ConvertDateToUTC(new Date())
                    mixpanel.track("Clicks on Add Workflow Templates", {
                        "alertTemplateName": obj.alertTemplateName,
                        "WorkflowName": obj.workflowName,
                        "CreatedOn": createdtime
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            await workFlowOperations(obj).then((resp) => {
                if (resp.status == null) {
                    showErrorToast('Error occurred while saving the workflow!')
                    return
                }
                if (resp.status == "Ok") {
                    showSuccessToast(resp.message)
                    let wfId = resp.workflowID
                    getWorkflows(wfId)
                    history.push(`/workflow/${wfId}`)
                }
                else {
                    showErrorToast(resp.message)
                }
            })
        }
    }

    const deleteWorkflow = async () => {
        if (id == 0) return
        let obj = {
            "action": "deleteWF",
            "workflowID": id
        }
        if (window.confirm("Are you sure? You want to delete the workflow?")) {
            await workFlowOperations(obj).then((resp) => {
                if (resp.status == "Ok") {
                    showSuccessToast(resp.message)
                    getWorkflows(0)
                }else{
                    showErrorToast(resp.message)
                }
            })
        }
    }

    //#region On Duplicate Workflow
    const onDuplicateWorkflow = () => {
        let duplicateObj = {
            eventTemplateID: 0,
            metadata: selectedWf.metadata,
            workflowID: 0,
            workflowMetadataID: 0,
            workflowName: ""
        }
        setSelectedWf(duplicateObj)
        setIsClicked2(true);
    }

    const handleInputChange = (e) => {
        if (isClicked2) {
            e.target.style.border = "";
            setIsClicked2(false)
        }
    };

    const onImport = () => {
        document.querySelector('.react-flow__controls-fitview').click(
            setTimeout(() => {
                toPng(document.querySelector('.react-flow'), {
                    filter: (node) => {
                        if (node?.classList?.contains('react-flow__minimap') || node?.classList?.contains('react-flow__controls')) {
                            return false
                        }
                        return true
                    }
                }).then(function (dataUrl) {
                    downloadImage(dataUrl, workflowName)
                })
            }, 100)
        )
    }
    const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    return (
        <>
            <div className="mainContentWithoutSB">
                <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                    <div className="col-lg-9 pt-2 FlowCharts">
                        <div className="row workflowinputrow">
                            <a onClick={() => history.push('/configuration', { from: "Workflow Page" })}>
                                <img src={BackArrow} style={{ paddingTop: "4px" }} alt="" />
                            </a>
                            <input
                                id="inputValue1"
                                placeholder="Please Enter Workflow Name"
                                type="text"
                                className="col wf-name mt-0"
                                value={workflowName}
                                style={{ border: isClicked2 ? "1px solid #FAAB20" : "" }}
                                onChange={(e) => {
                                    e.preventDefault()
                                    if (selectedWf != null)
                                        setWorkflowName(e.target.value)
                                    handleInputChange(e)
                                }} />

                            <button className="NextMoveButton addWorkFlow-saveWorkFlow-btn" onClick={() => onSaveWorkflow()}>
                                <span className="ViewUpdate">
                                    {selectedWf?.workflowID === 0 ? "Save Workflow" : "Update Workflow"}
                                </span>
                            </button>
                        </div>
                        <>
                            <div className="dndflow">
                                <ReactFlowProvider>
                                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                        <ReactFlow
                                            elements={elements}
                                            onConnect={onConnect}
                                            onElementsRemove={onElementsRemove}
                                            onLoad={onLoad}
                                            onDrop={onDrop}
                                            onDragOver={onDragOver}
                                            onNodeDoubleClick={onSelectElement}
                                            elementsSelectable={true}
                                            onEdgeUpdate={onEdgeUpdate}
                                            nodeTypes={nodeTypes}
                                            deleteKeyCode={
                                                isMacOS? 'Backspace' : 'Delete'
                                              }
                                        >
                                            <Controls />
                                        </ReactFlow>
                                    </div>
                                </ReactFlowProvider>
                            </div>
                        </>
                    </div>
                    <div className="col-lg-3 Configure">
                        <div style={{ padding: "inherit" }}>
                            {/* <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 300, hide: 400 }}
                                overlay={<Tooltip>Download Workflow</Tooltip>}>
                                <button className="del" onClick={onImport} disabled={true}>
                                    <img src={ImportIcon} style={{ height: "15px" }} alt="Import" />
                                </button>
                            </OverlayTrigger> */}

                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Duplicate Workflow</Tooltip>}>
                                <button className="del" onClick={onDuplicateWorkflow} >
                                    <img src={CopyIcon} style={{ height: "16px" }} alt="Copy" />
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Delete Workflow</Tooltip>}>
                                <button className="del" onClick={deleteWorkflow}>
                                    <img src={DelIcon} alt="Delete" />
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="ConfigureContent">
                            <div>
                                <img src={ConfigureIcon} alt="Configure" />
                                <p className="TextConfigure">Configure</p>
                            </div>
                            {
                                selectedNode == null &&
                                <ActionsList
                                    actions={userActions}
                                    onDrag={onActionDrag}
                                    setSelectedUser={setSelectedUser}
                                    selectedUser={selectedUser} />
                            }
                            {
                                selectedNode &&
                                <>
                                    <ConfigureAction
                                        setSelectedNode={setSelectedNode}
                                        selectedNode={selectedNode}
                                        masters={mastersList}
                                        onSave={onSaveFormData}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-3 MobileView" style={{ display: "none" }}>
                        <div className="MobileContent" style={{ padding: "10px", overflowY: "scroll" }}>
                            <pre>{elements && JSON.stringify(elements, null, 2)}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
